<!--虚拟券包管理-->
<template>
    <div id="party">
        <div class="table-search">
            <div class="search-L">
                <el-input v-model="tableParams.kw" placeholder="请输入券包名称搜索" @input="kwChange" clearable
                    class="mr15" />
                <el-select v-model="tableParams.codeStatus" placeholder="请选择券包状态" clearable @change="reload"
                    class="mr15">
                    <el-option v-for="item in ObjToOpt(virtualCouponStatusObj)" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>

            <div class="search-R">
                <el-button type="primary" @click="onAdd()">新增券包</el-button>
            </div>
        </div>
        <el-table :data="tableData" border stripe :header-cell-style="{
            background: '#FAFAFA',
            color: '#000000',
        }">

            <el-table-column label="券包名称" align="center" show-overflow-tooltip prop="packageName" />
            <el-table-column label="创建时间" align="center" show-overflow-tooltip prop="addDt" />
            <el-table-column label="可获取积分数" align="center" show-overflow-tooltip prop="exchangePoints" />
            <el-table-column label="适用门店" align="center" show-overflow-tooltip prop="suitShopName" />
            <el-table-column label="券码数量" align="center" show-overflow-tooltip prop="codeNum" />
            <el-table-column label="已核销数量" align="center" show-overflow-tooltip prop="codeUseNum" />

            <el-table-column label="核销有效期" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.startDate }} ～ {{ scope.row.endDate }}</span>
                </template>
            </el-table-column>

            <el-table-column label="券包状态" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ virtualCouponStatusObj[scope.row.codeStatus] }}</span>
                </template>
            </el-table-column>
            <el-table-column width="200" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="medium" @click.stop="writeOff(scope.row)"
                        v-if="scope.row.codeStatus == 1">代核销</el-button>
                    <el-button type="text" size="medium" @click.stop="exportCard(scope.row)"
                        v-if="scope.row.codeStatus == 1">导出券包</el-button>
                    <el-button type="text" size="medium" @click="goConfirmDeleteCard(scope.row)"
                        v-if="scope.row.codeStatus != 1">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 新增券包 -->
        <el-dialog :visible.sync="editShow" :title="'新增券包'" :close-on-click-modal="false" width="50%"
            @close="closeEditDialog('form-ref')">
            <el-form :model="formData" :rules="rules" ref="form-ref" label-width="120px">
                <el-form-item label="券包名称" prop="packageName">
                    <el-input v-model.trim="formData.packageName" maxlength="20" placeholder="请输入券包名称"></el-input>
                </el-form-item>
                <el-form-item label="可获取积分数" prop="exchangePoints">
                    <el-input v-model="formData.exchangePoints" type="number" :step="1" placeholder="请输入可获取积分数"
                        @keydown.native="(e) =>
                        (e.returnValue = ['e', 'E', '+', '-', '.'].includes(e.key)
                            ? false
                            : e.returnValue)
                            " @input="numberInputPoint"></el-input>

                </el-form-item>
                <el-form-item label="券码数量" prop="codeNum">
                    <el-input v-model="formData.codeNum" type="number" :step="1" placeholder="请输入券码数量" @keydown.native="(e) =>
                    (e.returnValue = ['e', 'E', '+', '-', '.'].includes(e.key)
                        ? false
                        : e.returnValue)
                        " @input="numberInput"></el-input>

                </el-form-item>
                <el-form-item label="核销有效期" prop="startDate">
                    <el-date-picker v-model="dateToDate" type="daterange" start-placeholder="开始日期"
                        end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="changeDate"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editShow = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
            </div>
        </el-dialog>
        <!-- 代核销 -->
        <el-dialog :visible.sync="writeOffDialog" :title="'代核销'" :close-on-click-modal="false" width="30%"
            @close="closeEditDialog('writeOff-form-ref')">
            <el-form :model="writeOffData" :rules="rules" ref="writeOff-form-ref" label-width="120px">
                <el-form-item label="核销券码" prop="codeValue"
                    :rules="{ required: true, message: '核销券码不能为空', trigger: 'blur' }">
                    <el-input v-model.trim="writeOffData.codeValue" autocomplete="off" maxlength="20"
                        placeholder="输入核销券码"></el-input>
                </el-form-item>
                <el-form-item label="关联用户" prop="memberId" :rules="{ required: true, message: '关联用户不能为空' }">
                    <el-select v-model="writeOffData.memberId" placeholder="选择用户" filterable remote
                        :remote-method="remoteMethod" :loading="loading" reserve-keyword>
                        <div style="min-height: 180px">
                            <el-option v-for="item in userList" :key="item.memberId"
                                :label="item.nickName + ' | ' + item.userMobile" :value="item.memberId">
                            </el-option>
                        </div>
                        <div class="custom-pagination">
                            <el-pagination background layout="prev, pager, next" :page-size="userParams.pageSize"
                                :total="userTotal" @current-change="handlePageChange" :current-page.sync="currentPage">
                            </el-pagination>
                        </div>
                    </el-select>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleWirteOff('writeOff-form-ref')">保存</el-button>
            </div>
        </el-dialog>
        <div class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </div>
    </div>
</template>
<script>

import { addCard, cardList, goExportCsv, delCard, exchange } from "@/api/card/virtual.js";
import { platformUser } from "@/api/card/card";
import { getUserList } from "@/api/user/user";
import { virtualCouponStatusObj } from "@/db/objs.js";
import { ObjToOpt } from "@/utils/utils";
import { rules } from "@/db/rules";

export default {
    components: {

    },

    data() {
        return {
            rules,
            ObjToOpt,
            virtualCouponStatusObj,
            currentPage: 1,
            tableData: [],
            total: 0,
            tableParams: {
                kw: "",
                page: 1,
                pageSize: 10,
                codeStatus: "",
                merchantId: 0,
                shopId: 0,
            },
            dateToDate: [],

            formData: {
                packageName: "",
                cardId: "",
                codeNum: '',
                startDate: '',
                endDate: '',
                merchantId: 0,
                shopId: 0,
            },
            editShow: false,
            writeOffDialog: false,
            userParams: {
                page: 1,
                pageSize: 10,
                kw: "",//查询关键字
                merchantId: "",//商户ID
                shopId: "",//店铺ID
            },
            userList: [],
            writeOffData: {},
            loading: false,
            rules: {
                exchangePoints: [{ required: true, message: '请输入可获取积分数' }],
                packageName: [
                    {
                        required: true,
                        message: "请输入券包名称",
                        trigger: "blur",
                    }
                ],
                codeNum: [{
                    required: true,
                    message: "请输入券码数量",
                    trigger: "blur",
                }],
                startDate: [
                    {
                        required: true,
                        message: "请选择开始时间",
                        trigger: "blur",
                    }
                ],
                endDate: [
                    {
                        required: true,
                        message: "请选择结束时间",
                        trigger: "blur",
                    }]
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            userTotal: 0,
            currentPage: 1,
        };
    },
    created() {

    },
    mounted() {

        this.getTableList();
    },
    methods: {
        reset() {
            this.dateToDate = []
            this.formData = {
                merchantId: 0, //商户ID
                shopId: 0, //店铺ID
            }
            this.writeOffData = {}
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.userParams.page = page;
            this.getUserList(1);
        },
        remoteMethod: _.debounce(function (query) {
            if (query !== '') {
                this.loading = true;
                this.userParams.kw = query;
                this.getUserList(1)
            } else {
                this.userList = [];
            }
        }, 500),
        // 【请求】表格数据
        getUserList(type) {
            platformUser(this.userParams).then((res) => {
                if (res.isSuccess == "yes") {
                    if (type == 1) {
                        this.loading = false
                    }
                    this.userTotal = res.data.total;
                    this.userList = res.data.list;
                }
            });
        },


        numberInput(value) {
            console.log(value)
            if (Number(value) > 1000) {
                this.formData.codeNum = 1000
                this.$message.error('券码数量限制输入1000及以内的正整数')
            }
            if (Number(value) == 0) {
                this.formData.codeNum = ''
                this.$message.error('券码数量需为大于0的正整数')
            }
            if (value.length > 8) { this.formData.codeNum = value.slice(0, 8) }
        },
        numberInputPoint(value) {
            if (Number(value) == 0) {
                this.formData.exchangePoints = ''
                this.$message.error('可获取积分数需为大于0的正整数')
            }
            if (value.length > 10) { this.formData.exchangePoints = value.slice(0, 10) }
        },
        // 查询列表
        getTableList() {
            cardList(this.tableParams).then((res) => {
                if (res.isSuccess == "yes") {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                }
            });
        },

        // 【监听】表格条数点击
        onSizeChange(size) {
            this.tableParams.pageSize = size;
            this.reload();
        },
        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.getTableList();
        },
        // 表格重载
        reload() {
            this.tableParams.page = 1;
            this.currentPage = 1;
            this.getTableList();
        },
        // 【监听】表格模糊查询
        kwChange: _.debounce(function () {
            this.reload();
        }, 500),

        // 删除卡券
        goDeleteCard(packageId) {
            delCard({ packageId }).then((res) => {
                if (res.isSuccess == "yes") {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                    });
                    this.getTableList();
                }
            });
        },
        // 二次确认删除卡券
        goConfirmDeleteCard(row) {
            let packageId = row.packageId;
            this.$confirm("请确定是否删除该券包?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // 接口
                    this.goDeleteCard(packageId);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        onAdd() {
            this.editShow = true;
        },
        changeDate(e) {
            if (e && e.length > 0) {
                this.formData.startDate = this.dateToDate[0];
                this.formData.endDate = this.dateToDate[1];
            } else {
                this.formData.startDate = null;
                this.formData.endDate = null;
            }
        },
        onSubmit: _.debounce(function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {


                    this.formData.packageType = 2
                    addCard(this.formData).then(res => {
                        if (res.isSuccess == "yes") {
                            this.$message.success("添加成功");
                            this.editShow = false;
                            this.getTableList();
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                } else {

                    return false;
                }
            });
        }, 800),
        closeEditDialog(formName) {
            this.reset()
            this.$nextTick(() => {
                this.$refs[formName].clearValidate();
            })
        },
        closeWriteOffDialog(formName) { },

        // 点击代核销
        writeOff(row) {
            this.writeOffDialog = true
            this.writeOffData.packageId = row.packageId
            this.getUserList()
        },
        userChange(e) {
            console.log(e)
        },
        // 执行代核销的逻辑
        handleWirteOff(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    exchange(this.writeOffData).then(res => {
                        if (res.isSuccess == "yes") {
                            this.$message.success("核销成功");
                            this.writeOffDialog = false;
                            this.getTableList();
                        }
                    })
                } else {

                    return false;
                }
            });
        },
        exportCard(row) {
            let params = {
                packageId: row.packageId,

            };
            let name = row.packageName;
            goExportCsv(params, name);
        }

    },
};
</script>
<style lang="scss" scoped>
#party {
    padding: 0.15rem;
    box-sizing: border-box;

    .table-search {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.2rem;

        .search-L {
            display: flex;
        }
    }

    .table-footer {
        margin-top: 0.1rem;
        display: flex;
        justify-content: flex-end;
    }
}
</style>