import request from '@/utils/request';
import Export from '@/utils/export';
// 用户列表
export function getUserList(data) {
  return request('post', '/apm/ums/shop/user/list', data)
}

// 卡券用户列表
export function getCardUserList(data) {
  return request('post', '/apm/ums/shop/user/user/list', data)
}

// 导出用户列表
export function getUserListExport(data) {
  return Export('post', '/apm/ums/shop/user/excelExport', data, "用户列表")
}

// 用户发放卡券
export function getGiveCard(data) {
  return request('post', '/apm/ums/shop/user/create/card', data)
}

// 用户基本信息
export function getUserInfo(data) {
  return request('post', '/apm/ums/shop/user/findById', data)
}
// 用户基本信息-平台商户端
export function getPUserInfo(data) {
  return request('post', '/apm/ums/shop/user/findByMemberId', data)
}
// 用户账户明细
export function getUserBalanceInfo(data) {
  return request('post', '/apm/ums/shop/user/accountDetailPage', data)
}
// 用户列表查询-平台商户端
export function getPUserList(data) {
  return request('post', '/apm/ums/shop/user/platform/pageList', data)
}
// 导出用户列表-平台商户端
export function getPUserListExport(data) {
  return Export('post', '/apm/ums/shop/user/excel/platform/list', data, "用户列表")
}
// 平台端用户count-平台商户端
export function getPUserCount(data) {
  return request('post', '/apm/ums/shop/user/platform/count', data)
}
// 成长值记录
export function getUserExRecord(data) {
  return request('post', '/apm/ums/shop/user/getMemberExpLogVoPage', data)
}
// 根据用户id获取等级历史记录
export function getUserLvRecord(data) {
  return request('post', '/apm/ums/shop/user/getMemberLevelLogVoList', data)
}
// 门店赠送金额概况（分页）
export function shopGiveAmount(data) {
  return request('post', '/apm/ums/shop/user/shopGiveAmountOverview/page', data)
}
// 门店赠送金额总概况
export function shopGiveAllAmount(data) {
  return request('post', '/apm/ums/shop/user/shopGiveAmountOverview', data)
}